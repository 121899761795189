<template>
  <div>
    <SiteHeaderComponent header="Shop" :showBackButton="true" />
    <v-container
      v-if="
        lbaShopInfoApps.length == 0 &&
          lbaShopBdeApps.length == 0 &&
          lbaShopAzeApps.length == 0
      "
    >
      <h3 class="mt-4 pa-4 pl-8 text-center" style="font-size: 2em">
        Sie besitzen bereits alle LBA-Apps
      </h3>
    </v-container>
    <v-container v-if="lbaShopInfoApps.length != 0">
      <h2
        class="mt-4 white pa-4 pl-8 elevation-2 rounded-lg"
        style="font-size: 2em"
      >
        <v-icon color="black" class="pr-4" size="1.2em"
          >mdi-information-outline</v-icon
        >
        LBA-INFO
      </h2>

      <v-container class="d-flex flex-wrap justify-center justify-md-start">
        <HomeAppCard
          v-for="app in lbaShopInfoApps"
          @openDialog="openDialogMethod(app.appBezeichnung)"
          :key="app.appId"
          :name="app.appBezeichnung"
          :url="app.url"
        />
      </v-container>
    </v-container>
    <v-container v-if="lbaShopBdeApps.length != 0">
      <h2
        class="mt-4 white pa-4 pl-8 elevation-2 rounded-lg"
        style="font-size: 2em"
      >
        <v-icon color="black" class="pr-4" size="1.2em"
          >mdi-chart-timeline-variant</v-icon
        >
        LBA-BDE
      </h2>

      <v-container class="d-flex flex-wrap justify-center justify-md-start">
        <HomeAppCard
          v-for="app in lbaShopBdeApps"
          @openDialog="openDialogMethod(app.appBezeichnung)"
          :key="app.appId"
          :name="app.appBezeichnung"
        />
      </v-container>
    </v-container>
    <v-container v-if="lbaShopAzeApps.length != 0">
      <h2
        class="mt-4 white pa-4 pl-8 elevation-2 rounded-lg"
        style="font-size: 2em"
      >
        <v-icon color="black" class="pr-4" size="1.2em"
          >mdi-calendar-month</v-icon
        >
        LBA-AZE
      </h2>

      <v-container class="d-flex flex-wrap justify-center justify-md-start">
        <HomeAppCard
          v-for="app in lbaShopAzeApps"
          @openDialog="openDialogMethod(app.appBezeichnung)"
          :key="app.appId"
          :name="app.appBezeichnung"
        />
      </v-container>
    </v-container>
    <v-dialog
      v-model="openDialog"
      scrollable
      max-width="700"
      @keydown.esc="openDialog = false"
    >
      <v-card>
        <v-card-title class="mx-0 px-0 my-0 py-0">
          <v-toolbar color="primary" class="mx-0 px-0 my-0 py-0" dark fixed top>
            <!-- Button zum Schließen des Dialogs -->
            <v-icon @click="openDialog = false">mdi-close</v-icon>
            <!--Überschrift-->
            <v-toolbar-title class="flex text-center"
              >App: {{ appName }}</v-toolbar-title
            >
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-8">
          <h2 class="mb-5">{{ appName }}</h2>
          <p v-if="appName == 'LBA-Artikel'">
            Die LBA-Artikel App ist dafür da ihre Artikel mit einer
            Volltextsuche, einem Filter nach vielen verschiedenen Merkmalen oder
            auch einem Barcode Scanner zu suchen und viele Daten des Artikels
            anzusehen. Neben den allgemeinen Informationen gibt es auch eine
            Statistik die Informationen über den Umsatz des Artikels
            bereitstellen
          </p>
          <p v-if="appName == 'LBA-CRM'">
            Die LBA-CRM App ist dafür da ihre Adressen mit einer Volltextsuche
            oder einem Filter zu suchen und verschiedenste Informationen
            anzusehen.
          </p>
          <p v-if="appName == 'LBA-Auftrag'">
            Die LBA-Auftrag App kann dafür verwendet werden, dass Sie einen
            Auftrag oder ein Angebot erstellt. Den Auftrag können Sie sich nach
            der Erstellung in der Dokumentansicht ansehen. Coming soon: Auftrag
            aus Angebot erstellen.
          </p>
          Falls Sie {{ appName }} benutzen wollen, melden sie sich bei:
          <br />
          E-Mail: ??? <br />
          Telefonnummer: ???
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HomeAppCard from '@/components/home/HomeAppCard.vue'
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import store from '@/store'

export default {
  data() {
    return {
      tab: 0,
      openDialog: false,
      appName: ''
    }
  },
  components: {
    SiteHeaderComponent,
    HomeAppCard
  },
  computed: {
    ...mapGetters([
      'availableShopApps',
      'lbaShopInfoApps',
      'lbaShopBdeApps',
      'lbaShopAzeApps'
    ])
  },
  methods: {
    openDialogMethod(appName) {
      this.openDialog = true
      this.appName = appName
    }
  },
  created: () => {
    store.dispatch('loadShopAppsAvailableForCurrentUser')
  }
}
</script>
